import {Component, OnInit} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/AlkiraAdminWeb/services/admin.service';
import { TranslateService } from '@ngx-translate/core';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavRightComponent implements OnInit {
  first_name: any;
  last_name: any;
  selected_laguage: any = 'fr';
  selected_laguage_value: string;

  constructor(
    private router: Router,
    public adminservice:AdminService,
    public translateService:TranslateService,
    private ngxLoader: NgxUiLoaderService
  ) { }

  // tslint:disable-next-line:typedef
  ngOnInit() {
    if (window.localStorage.getItem('app_language')) {
      this.selected_laguage = window.localStorage.getItem('app_language');
    }
    this.first_name = window.localStorage.getItem('first_name');
    this.last_name = window.localStorage.getItem('last_name');
   }

   changeLanguage(){
    this.adminservice.updateadminlanguage(this.selected_laguage_value).subscribe(res => {
      if (res.status === true) {
        this.ngxLoader.stopAll();
        Swal.fire('', res.message, 'success');
        console.log(res);
        if(this.selected_laguage_value=='ENG'){
          window.localStorage.setItem('app_language', 'en');
          window.location.reload();
        }else{
          window.localStorage.setItem('app_language', 'fr');
          window.location.reload();
        }
      
      
      } else {
        this.ngxLoader.stopAll();
        console.log(res);
        this.translateService.get('app').subscribe((translate) => {
          this.translateService.get('app').subscribe((translate) => {
            Swal.fire({
              icon: 'error',
              title: translate.oops,
              text: res.message
            });
          });
        });
      }
    }, error => {
      this.ngxLoader.stopAll();
      this.translateService.get('app').subscribe((translate) => {
        Swal.fire({
          icon: 'error',
          title: translate.oops,
          text: translate.error_msg
        });
      });
      console.error(error);
    });
   }
  // tslint:disable-next-line:typedef
  logout() {
    window.localStorage.clear();
    this.router.navigate(['/auth/login']);
  }

  profile() {
    this.router.navigate(['/my-profile']);
  }

  englishLang() {
   
    this.selected_laguage_value='ENG';
    this.changeLanguage();
 
  }
  frenchLang() {
  
    this.selected_laguage_value='FR';
    this.changeLanguage();

  }
}

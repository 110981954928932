import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class AuthGuard implements CanActivate {
  private userPermissions: Map<string, Set<string>> = new Map<string, Set<string>>();
  permissionMap: any;
  constructor(private jwtHelper: JwtHelperService, private router: Router, private http: HttpClient) {

    this.permissionMap = JSON.parse(window.localStorage.getItem('userPermissionCheck'));
    if (this.permissionMap) {
      const permissionnew = this.permissionMap.map(item => {
        const operationRouteArray = item.operation_route.split(",");
        this.userPermissions.set(item.moduleaccess_module.module_route, new Set<string>(operationRouteArray));
      })
      console.log("this.userPermissions 2", this.userPermissions);
    }
  }
  // tslint:disable-next-line:typedef
  async canActivate(next: ActivatedRouteSnapshot) {
    const requiredPermissions = next.data.requiredPermissions as string[];
    const moduleId = next.data.moduleId as number;
    console.log("requiredPermissions", requiredPermissions)
    console.log("moduleId", moduleId)
    const token = localStorage.getItem('accessToken');

    if (token && !this.jwtHelper.isTokenExpired(token)) {
      // console.log(this.jwtHelper.decodeToken(token));
      if (requiredPermissions[0] != "no") {
        if (this.hasPermissions(moduleId, requiredPermissions)) {
          return true;
        } else {

          console.log("Access Denied")
         return this.router.createUrlTree(['/auth/access-denied']);


        }
      } else {
        return true;
      }

    } else {
      this.router.navigate(['/auth/login']);
    }
  }

  private hasPermissions(moduleId: number, requiredPermissions: string[]): boolean {
    console.log("moduleId, requiredPermissions")
    const userModulePermissions = this.userPermissions.get(moduleId[0]);
    console.log("userModulePermissions")
    if (!userModulePermissions) {
      return false; // Module not found in user's permissions
    }
    return requiredPermissions.every(permission => userModulePermissions.has(permission));
  }

}

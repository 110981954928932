import {Component, Input, NgZone, OnInit} from '@angular/core';
import {NavigationItem} from '../../navigation';
import {Location} from '@angular/common';
import {NextConfig} from '../../../../../../app-config';

@Component({
  selector: 'app-nav-group',
  templateUrl: './nav-group.component.html',
  styleUrls: ['./nav-group.component.scss']
})
export class NavGroupComponent implements OnInit {
  @Input() item: NavigationItem;
  // tslint:disable-next-line:no-inferrable-types
  @Input() layout1: boolean = false;
  @Input() activeId: any;
  public nextConfig: any;
  permissionMap: any;

  constructor(private zone: NgZone, private location: Location) {
    if (window.localStorage.getItem('userPermission')){
      this.permissionMap = new Map(JSON.parse(window.localStorage.getItem('userPermission')));
    }
    this.nextConfig = NextConfig.config;
  }

  // tslint:disable-next-line:typedef
  ngOnInit() {
    // at reload time active and trigger link
    // tslint:disable-next-line:variable-name
    let current_url = this.location.path();
    // tslint:disable-next-line:no-string-literal
    if (this.location['_baseHref']) {
      // tslint:disable-next-line:no-string-literal
      current_url = this.location['_baseHref'] + this.location.path();
    }
    // tslint:disable-next-line:quotemark
    const link = "a.nav-link[ href='" + current_url + "' ]";
    const ele = document.querySelector(link);
    if (ele !== null && ele !== undefined) {
      const parent = ele.parentElement;
      // tslint:disable-next-line:variable-name
      const up_parent = parent.parentElement.parentElement;
      // tslint:disable-next-line:variable-name
      const last_parent = up_parent.parentElement;
      if (parent.classList.contains('pcoded-hasmenu')) {
        // tslint:disable-next-line:no-string-literal
        if (this.nextConfig['layout'] === 'vertical') {
          parent.classList.add('pcoded-trigger');
        }
        parent.classList.add('active');
      } else if (up_parent.classList.contains('pcoded-hasmenu')) {
        // tslint:disable-next-line:no-string-literal
        if (this.nextConfig['layout'] === 'vertical') {
          up_parent.classList.add('pcoded-trigger');
        }
        up_parent.classList.add('active');
      } else if (last_parent.classList.contains('pcoded-hasmenu')) {
        // tslint:disable-next-line:no-string-literal
        if (this.nextConfig['layout'] === 'vertical') {
          last_parent.classList.add('pcoded-trigger');
        }
        last_parent.classList.add('active');
      }
    }
  }

}

import { Injectable } from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
  module_route?: string;
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'other',
    title: 'Home',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {
        id: 'dashboard-page',
        title: 'Dashboard',
        type: 'item',
        url: '/home',
        classes: 'nav-item',
        icon: 'feather icon-home',
        module_route: 'default'

      }
    ]
  },
  {
    id: 'other',
    title: 'Management',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {
        id: 'user',
        title: 'Users',
        type: 'collapse',
        icon: 'feather icon-user-check',
        children: [
          {
            id: 'user-list',
            title: 'User List',
            type: 'item',
            url: '/user/user-list',
            module_route: 'user/user-list'
          },
          {
            id: 'archive',
            title: 'Archived List',
            type: 'item',
            url: '/user/archived-user-list',
            module_route: 'user/archived-user-list'
          },
          {
            id: 'deleted',
            title: 'Deleted List',
            type: 'item',
            url: '/user/deleted-user-list',
            module_route: 'user/deleted-user-list'
          }
        ]
      },
      {
        id: 'tenant',
        title: 'Tenants',
        type: 'item',
        icon: 'feather icon-users',
        classes: 'nav-item',
        url: '/tenant',
        module_route: 'tenant'
      },
      {
        id: 'real-estate-manager',
        title: 'Real Estate Managers',
        type: 'item',
        icon: 'feather icon-target',
        classes: 'nav-item',
        url: '/real-estate-manager',
        module_route: 'real-estate-manager'
      },
      {
        id: 'asset',
        title: 'Assets',
        type: 'item',
        icon: 'feather icon-server',
        classes: 'nav-item',
        url: '/asset',
        module_route: 'asset'
      },


      {
        id: 'contract',
        title: 'Contracts',
        type: 'collapse',
        icon: 'feather icon-file-text',
        children: [
          {
            id: 'contarctcompleted',
            title: 'ContractCompleted',
            type: 'item',
            url: '/contract/completed',
            module_route: 'contract/completed'
          },
          {
            id: 'contractpending',
            title: 'ContractPending',
            type: 'item',
            url: '/contract/pending',
            module_route: 'contract/pending'
          }
        ]
      },

      // {
      //   id: 'inventory',
      //   title: 'Inventories',
      //   type: 'item',
      //   icon: 'feather icon-shopping-cart',
      //   classes: 'nav-item',
      //   url: '/inventory',
      //   module_route: 'inventory'
      // },
      {
        id: 'repair',
        title: 'Repairs',
        type: 'item',
        icon: 'feather icon-activity',
        classes: 'nav-item',
        url: '/repair',
        module_route: 'repair'
      },

      {
            id: 'role',
            title: 'Role & Permission',
            type: 'item',
            icon: 'feather icon-shield',
            classes: 'nav-item',
            url: '/admin_roles/role',
            module_route: 'admin_roles/role'
      },

      // {
      //   id: 'roleandpermission',
      //   title: 'Role & Permission',
      //   type: 'collapse',
      //   icon: 'feather icon-shield',
      //   children: [
      //     // {
      //     //   id: 'modulesetting',
      //     //   title: 'Module Setting',
      //     //   type: 'item',
      //     //   url: '/admin_roles/module',
      //     //   module_route: 'admin_roles/module'
      //     // },
      //     {
      //       id: 'role',
      //       title: 'Role & Permission',
      //       type: 'item',
      //       url: '/admin_roles/role',
      //       module_route: 'admin_roles/role'
      //     }
      //   ]
      // },
      // {
      //   id: 'booking-list',
      //   title: 'Booking-List',
      //   type: 'collapse',
      //   icon: 'feather icon-activity',
      //   children: [
      //     {
      //       id: 'delivery booking-list',
      //       title: 'Delivery',
      //       type: 'item',
      //       url: '/booking-list/delivery',
      //       module_route: 'booking-list/delivery'
      //     },
      //     {
      //       id: 'moving-agency booking-list',
      //       title: 'Moving-Agency',
      //       type: 'item',
      //       url: '/booking-list/moving-agency',
      //       module_route: 'booking-list/moving-agency'
      //     },
      //     {
      //       id: 'service booking-list',
      //       title: 'On Demand Service',
      //       type: 'item',
      //       url: '/booking-list/service-booking',
      //       module_route: 'booking-list/service-booking'
      //     },
      //   ]
      // },
      // {
      //   id: 'service-provider',
      //   title: 'Service-provider',
      //   type: 'collapse',
      //   icon: 'feather icon-shopping-cart',
      //   children: [
      //     {
      //       id: 'mp-provider-list',
      //       title: 'MP - Provider List',
      //       type: 'item',
      //       url: '/service-provider/mp-provider',
      //       module_route: 'service-provider/mp-provider'
      //     },
      //     {
      //       id: 'demand-provider-list',
      //       title: 'Demand - Provider List',
      //       type: 'item',
      //       url: '/service-provider/demand-provider',
      //       module_route: 'service-provider/demand-provider'
      //     },
      //   ]
      // },
      // {
      //   id: 'settlement',
      //   title: 'Settlement',
      //   type: 'collapse',
      //   icon: 'feather icon-server',
      //   children: [
      //     {
      //       id: 'settlement-list',
      //       title: 'Settlement List',
      //       type: 'item',
      //       url: '/settlement/settlement-list'
      //     },
      //     {
      //       id: 'make-settlement',
      //       title: 'Make Settlement',
      //       type: 'item',
      //       url: '/settlement/make-settlement'
      //     },
      //   ]
      // },
      // {
      //   id: 'roleandpermission',
      //   title: 'Role & Permission',
      //   type: 'collapse',
      //   icon: 'feather icon-shield',
      //   children: [
      //     {
      //       id: 'modulesetting',
      //       title: 'Module Setting',
      //       type: 'item',
      //       url: '/admin_roles/module',
      //       module_route: 'admin_roles/module'
      //     },
      //     {
      //       id: 'role',
      //       title: 'Role & Permission',
      //       type: 'item',
      //       url: '/admin_roles/role',
      //       module_route: 'admin_roles/role'
      //     }
      //   ]
      // },
      // {
      //   id: 'coupon',
      //   title: 'Coupon',
      //   type: 'item',
      //   icon: 'feather icon-target',
      //   classes: 'nav-item',
      //   url: '/coupon',
      //   module_route: 'coupon'
      // }
    ]
  },
  {
    id: 'other',
    title: 'Master',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {
        id: 'master',
        title: 'Master Setting',
        type: 'collapse',
        icon: 'feather icon-layers',
        children: [
          {
            id: 'professional-management',
            title: 'Professional Management',
            type: 'item',
            url: '/master/professional-management',
            module_route: 'master/professional-management'
          },
          // {
          //   id: 'item-type-management',
          //   title: 'Item Type Management',
          //   type: 'item',
          //   url: '/master/item-type-management',
          //   module_route: 'master/item-type-management'
          // },
          // {
          //   id: 'item-management',
          //   title: 'Item Management',
          //   type: 'item',
          //   url: '/master/item-management',
          //   module_route: 'master/item-management'
          // },
          {
            id: 'country-management',
            title: 'Country Management',
            type: 'item',
            url: '/master/country-management',
            module_route: 'master/country-management'
          },
          {
            id: 'city-management',
            title: 'City Management',
            type: 'item',
            url: '/master/city-management',
            module_route: 'master/city-management'
          },
          {
            id: 'repair-type-management',
            title: 'Repair Type Management',
            type: 'item',
            url: '/master/repair-type-management',
            module_route: 'master/repair-type-management'
          },
        ]
      },
    ]
  },
  {
    id: 'other',
    title: 'CMS Pages',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'aboutUS',
        title: 'About Us',
        type: 'item',
        icon: 'feather icon-book',
        classes: 'nav-item',
        url: '/pages/about-us',
        module_route: 'pages/about-us'
      },
      {
        id: 'privacy-policy',
        title: 'Privacy Policy',
        type: 'item',
        icon: 'feather icon-box',
        classes: 'nav-item',
        url: '/pages/privacy-policy',
        module_route: 'pages/privacy-policy'
      },
      {
        id: 'terms-and-condition',
        title: 'TermsandConditions',
        type: 'item',
        icon: 'feather icon-gitlab',
        classes: 'nav-item',
        url: '/pages/terms-and-condition',
        module_route: 'pages/terms-and-condition'
      },
      {
        id: 'pricing-section',
        title: 'PricingSection',
        type: 'item',
        icon: 'feather icon-gitlab',
        classes: 'nav-item',
        url: '/pages/pricing-section',
        module_route: 'pages/pricing-section'
      },
    ]


  },
  // {
  //   id: 'report',
  //   title: 'Report',
  //   type: 'group',
  //   icon: 'feather icon-align-left',
  //   children: [
  //     {
  //       id: 'payment',
  //       title: 'Payment',
  //       type: 'item',
  //       icon: 'feather icon-file-plus',
  //       classes: 'nav-item',
  //       url: '/payment'
  //     },
  //     {
  //       id: 'report',
  //       title: 'Report',
  //       type: 'item',
  //       icon: 'feather icon-file-text',
  //       classes: 'nav-item',
  //       url: '/report'
  //     },
  //   ]
  // }
];

@Injectable()
export class NavigationItem {
  // tslint:disable-next-line:typedef
  public get() {
    return NavigationItems;
  }
}

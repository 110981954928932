import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import {AuthComponent} from './theme/layout/auth/auth.component';
import { AuthGuard } from './AlkiraAdminWeb/guards/auth-guard.service';

const routes: Routes = [
  
  {
    path: '',
    component: AdminComponent,
    canActivate: [AuthGuard],
    data: { requiredPermissions: ['no'], moduleId: [0] },
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        data : { full_module_route : 'default'},
        loadChildren: () => import('./AlkiraAdminWeb/dashboard/dashboard.module').then(module => module.DashboardModule)
      },
      {
        path: 'tenant',
        data : { full_module_route : 'tenant'},
        loadChildren: () => import('./AlkiraAdminWeb/tenant/tenant.module').then(module => module.TenantModule)
      },
      {
        path: 'contract',
        data : { full_module_route : 'contract'},
        loadChildren: () => import('./AlkiraAdminWeb/contract/contract.module').then(module => module.ContractModule)
      },
      {
        path: 'inventory',
        data : { full_module_route : 'inventory'},
        loadChildren: () => import('./AlkiraAdminWeb/inventory/inventory.module').then(module => module.InventoryModule)
      },
      {
        path: 'repair',
        data : { full_module_route : 'repair'},
        loadChildren: () => import('./AlkiraAdminWeb/repair/repair.module').then(module => module.RepairModule)
      },
      {
        path: 'my-profile',
        data : { full_module_route : 'my-profile'},
        loadChildren: () => import('./AlkiraAdminWeb/my-profile/my-profile.module').then(module => module.MyProfileModule)
      },
      {
        path: 'admin_roles',
        data : { full_module_route : 'admin_roles'},
        loadChildren: () => import('./AlkiraAdminWeb/roleandpermission/roleandpermission.module').then(module => module.RoleandpermissionModule)
      },
      {
        path: 'user',
        data : { full_module_route : 'user'},
        loadChildren: () => import('./AlkiraAdminWeb/admin/admin.module').then(module => module.AdminModule)
      },
      {
        path: 'real-estate-manager',
        data : { full_module_route : 'real-estate-manager'},
        loadChildren: () => import('./AlkiraAdminWeb/real-estate-manager/real-estate-manager.module').then(module => module.RealEstateManagerModule)
      },
      {
        path: 'asset',
        data : { full_module_route : 'asset'},
        loadChildren: () => import('./AlkiraAdminWeb/asset/asset.module').then(module => module.AssetModule)
      },
     
   
      {
        path: 'master',
        data : { full_module_route : 'master'},
        loadChildren: () => import('./AlkiraAdminWeb/master/master.module').then(module => module.MasterModule)
      },
      {
        path: 'pages',
        data : { full_module_route : 'pages'},
        loadChildren: () => import('./AlkiraAdminWeb/cms/cms.module').then(module => module.CmsModule)
      },
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      // {
      //   path: '',
      //   redirectTo: 'login',
      //   pathMatch: 'full'
      // },
      {
        path: 'auth',
        loadChildren: () => import('./AlkiraAdminWeb/authentication/authentication.module').then(module => module.AuthenticationModule)
      },
     
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
